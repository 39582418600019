@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-greens-50 dark:bg-purples;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  counter-reset: mega-step, mini-step;
}

::-webkit-scrollbar {
    width: 10px;
    height:10px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-gray-900 outline outline-1 outline-gray-200 dark:outline-gray-800 ;

 
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-600 rounded-full ;

}