@tailwind base;
@tailwind components;
@tailwind utilities;

.step-number::before {
  content: "hello";
  /* position: absolute;
  left: 50%;
  top: 120px;
  bottom: -3rem;
  margin-left: -1px;
  border-left: 2px dashed #eaeff4; */
}

/* .step-element:last-child .step-number::before {
  bottom: 1rem;
}
.step-element {
  display: flex;
  padding: 1rem 0;
} */

.code-block {
  @apply text-gray-900 dark:text-white mt-20;
}

.code-block h1{
  @apply text-xl font-bold;
}
.code {
  @apply rounded-lg bg-gray-900 bg-opacity-80 dark:bg-opacity-60 !important;
  /* background-color: rgba(40, 36, 36, 0.76)!important; */
}

.code-block ul li{
  @apply font-bold indent-1;
}

.code-block ul li ul li{
  @apply indent-8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p code , li code{
  @apply  text-red-600;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}